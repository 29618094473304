<template>
  <div>
    <button-element size="xsmall" type="warning" @click="onEditButtonClick">
      <div class="flex items-center gap-x-2">
        <fa-icon icon="edit"/>
        Adresse
      </div>
    </button-element>

    <modal ref="changeAddressModal" title="Changement d'adresse">
      <delivery-contact-select-input v-if="showSelectInput" v-model="selectedDeliveryContact" :member-id="getMemberId"
                                     :show-selection-info="true" class="h-56"
                                     selection-card-classes="w-4xl"></delivery-contact-select-input>

      <template #footer>
        <div class="flex justify-between gap-x-16">
          <button-element :disabled="selectedDeliveryContact === null" type="primary"
                          @click="changeDeliveryContact">
            <fa-icon class="mr-2" icon="check"></fa-icon>
            Confirmer
          </button-element>

          <button-element type="light" @click="closeModal">
            <fa-icon class="mr-2" icon="times"></fa-icon>
            Annuler
          </button-element>
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
import ButtonElement from "../../../../elements/buttons/ButtonElement";
import Modal from "../../../../elements/modals/Modal";
import DeliveryContactSelectInput from "../../../../elements/forms/elements/DeliveryContactSelectInput";
import OrderRepository from "../../../../../repositories/OrderRepository";

export default {
  name: "EditOrderChangeAddress",
  components: {DeliveryContactSelectInput, Modal, ButtonElement},
  data: () => ({
    showSelectInput: false,
    selectedDeliveryContact: null,
  }),
  methods: {
    onEditButtonClick() {
      this.$refs.changeAddressModal.openModal();
      this.showSelectInput = true;
    },
    closeModal() {
      this.$refs.changeAddressModal.closeModal();
      this.selectedDeliveryContact = null;
    },
    changeDeliveryContact() {
      if (!this.selectedDeliveryContact)
        return false;

      OrderRepository.changeDeliveryContact(this.getOrderId, this.selectedDeliveryContact.id).then(() => {
        this.$sendSuccessToast('Contact de livraison changé.');
        this.$store.dispatch('order/refreshOrder').then(() => {
          this.closeModal();
        });
      });
    }
  },
  computed: {
    getMemberId() {
      return this.$store.getters['order/getMemberId'];
    },
    getOrderId() {
      return this.$store.getters['order/getOrderId'];
    }
  },
}
</script>

